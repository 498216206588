import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomersDnsList() {
    // Use toast
    const toast = useToast()

    const refCustomerDnsListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'customerName', sortable: false, label:'Cliente' },
        { key: 'dns', sortable: false },
        { key: 'actions' },
    ]


    const perPage = ref(10)
    const totalCustomerDns = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)


    const dataMeta = computed(() => {
        const localItemsCount = refCustomerDnsListTable.value ? refCustomerDnsListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCustomerDns.value,
        }
    })

    const refetchData = () => {
        refCustomerDnsListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery ], () => {
        refetchData()
    })

    const fetchAllCustomerDns = (ctx, callback) => {
        store
            .dispatch('app-customer/fetchAllCustomerDns', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value
            })
            .then(response => {
                const customers = response.data.data;
                callback(customers)
                totalCustomerDns.value = response.data.totalItems
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching customers list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    return {
        fetchAllCustomerDns,
        tableColumns,
        perPage,
        currentPage,
        totalCustomerDns,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCustomerDnsListTable,
        refetchData
    }
}
