
<template>
  <div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show')}}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Cliente ou DNS" />
              <b-button v-if="!user.role.endsWith('VI')" variant="primary" :to="{ name: 'register-customer-dns' }">
                <span class="text-nowrap">{{ $t('Adicionar DNS')}}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refCustomerDnsListTable" class="position-relative" :items="fetchAllCustomerDns" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

        <!-- Column: Customer name -->
        <template #head(customerName)>
          {{ $t('Customer')}}
        </template>
        <template #cell(customerName)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.customerName }}
          </div>
        </template>

        <!-- Column:  name -->
        <template #head(dns)>
          {{ $t('DNS')}}
        </template>
        <template #cell(dns)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.dns }}
          </div>
        </template>


        <!-- Column: Actions -->
        <template #head(actions)>
          {{ $t('Actions')}}
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{ name: 'register-customer-dns', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit')}}</span>
            </b-dropdown-item>
           
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalCustomerDns" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'

import store from '@/store'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import customerStoreModule from "@/views/main/customer/customerStoreModule";
import useCustomersDnsList from "@/views/main/customer/useCustomerDnsList";
import vSelect from 'vue-select'

export default {
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  setup(){
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'
    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    
    const {
      fetchAllCustomerDns,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerDns,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerDnsListTable,
      refetchData
    } = useCustomersDnsList()
    
    return {
      fetchAllCustomerDns,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerDns,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerDnsListTable,
      refetchData,

      user
    }
  }
}

</script>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>